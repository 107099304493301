/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { blogMDXComponents } from '../utils/helpers/blog-mdx';

import BaseLayout from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SEO from '../components/atoms/utility/seo';
import Spacer from '../components/atoms/utility/Spacer';

export default function LegalTemplate({ data }) {
  const post = data.mdx;

  return (
    <BaseLayout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <div className='max-w-4xl w-full mx-auto'>
        <div className='my-10 lg:my-20 mx-auto w-full'>
          <PrimaryHeader headerText={post.frontmatter.title} />
          <MDXProvider components={blogMDXComponents}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
      <Spacer />
    </BaseLayout>
  );
}

export const query = graphql`
    query legalTemplateQuery($id: String!) {
        mdx(id: { eq: $id }) {
            body
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
    }
`;
